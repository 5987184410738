.ViewMeal-Master .dropdown {
    cursor: default;
}

.ViewMeal-Master .answer-text {
    border: 1px solid #d8dbe0;
    border-radius: 25px;
    padding: 10px;
    color: #2c384af2;
    min-width: 120px;
    text-align: center;
}