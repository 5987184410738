.tag-sections-main {
    margin: 0px 0 20px 0;
    padding: 0px 0;
    border-bottom: 1px solid #d8dbe0;
}

.button-section {
    margin: 25px 0;
}

.tag-sections-main .tag-card {
    color: var(--white);
    position: relative;
    padding: 5px 15px 8px 15px;
    border-radius: 6px;
    min-width: auto;
    text-align: center;
    background: antiquewhite;
    width: auto;
    margin-top: 0px;
}

.tag-sections-main p {
    margin: 10px 0;
}

.tag-sections-main .tag-card-main {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 10px 0;

}

.tag-sections-main .tag-close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    cursor: pointer;
}

.boxnMealMatrCut {
    display: flex;
    gap: 25px;
    padding-bottom: 0%;
}

p.mealMeatTitl {
    font-size: 16px !important;
    font-weight: 400;
}