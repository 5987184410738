.user-table-sec {
  .add-user {
    background-color: $buttonClr;
    // border: 1px solid $buttonClr;
    padding: 10px 45px;
    border-radius: 5px;
    color: $white;
    text-decoration: none;
    border-right: 6px solid $buttonShadow;
    border-bottom: 6px solid $buttonShadow;
  }

  .input-section {
    margin-bottom: 15px;

    input {
      border-radius: 40px;
      padding: 10px 20px;
      font-size: 14px;
    }

    .search-icon {
        top: 48%;
        transform: translateY(-50%);
        right: 20px;
        width: 20px;
    }
  }
}

.user-manage-view-sec {
  .box-section {
    border: 1px solid $purple;
    border-bottom: 5px solid $purple;
    border-right: 5px solid $purple;
    border-radius: 10px;
    margin-top: 10px;
  }

  .viewbox-top-sec {
    padding: 5px 15px 15px 15px;

    .button-shaows {
      width: 100%;
      background-color: $buttonClr;
      border-right: 6px solid $buttonShadow;
      border-bottom: 6px solid $buttonShadow;
      color: $white;
      padding: 6px 5px;
    }

    .sale-btn {
      width: 100%;
      background-color: transparent;
      border: 1px solid $green;
      border-right: 6px solid $green;
      border-bottom: 6px solid $green;
      color: $green;
      padding: 6px 5px;
      font-weight: bold;
    }
  }

  .tab-sec {
    .top-nav {
      border-top: 1px solid $light-axe;
      border-bottom: 1px solid $light-axe;

      .nav-link.active {
        background-color: transparent;
        border-bottom: 4px solid $dark-blue;
        color: $dark-blue;
        border-left: 0px;
        border-top: 0px;
        border-right: 0px;
      }

      li {
        border-right: 1px solid $light-axe;
        flex: 9%;

        button {
          width: 100%;
          padding-left: 3px;
          padding-right: 3px;
        }
      }
    }

    .tab-content {
      padding: 10px 15px;
    }

    .value-text {
      color: $dark-blue;
      font-weight: 600;
    }

    .value-purple {
      color: $dark-purple;
    }
  }

  .profile-btn {
    background-color: $dark-purple;
    border-right: 6px solid $light-purple;
    border-bottom: 6px solid $light-purple;
    padding: 10px 25px;
    color: #fff;
    border: 1px solid $dark-purple;
  }

  .ktc-sec,
  .diet-sec {
    .ktc-nav {
      border-bottom: 0px;
      gap: 10px;
      justify-content: center;

      .nav-link {
        border: 1px solid $light-axe;
        border-radius: 5px;
        padding: 0.4rem 0.7rem;
      }

      .nav-link.active {
        border-color: 2px solid $dark-blue;
        border-right: 4px solid $dark-blue;
        border-bottom: 4px solid $dark-blue;
        background-color: transparent;
        color: $dark-blue;
      }
    }
  }

  .service-sec {
    .service-box {
      border: 2px solid $dark-blue;
      border-right: 4px solid $dark-blue;
      border-bottom: 4px solid $dark-blue;
      margin: 10px;
      padding: 20px;
      border-radius: 10px;
    }

    .slick-prev:before,
    .slick-next:before {
      opacity: 1;
      color: $purple;
    }

    .plan-deatails-sec {
      border: 2px solid $green;
      border-right: 4px solid $green;
      border-bottom: 4px solid $green;
      padding: 8px 10px;
      width: 50%;
    }
  }

  .mwq-sec {
    .mwq-left-card {
      border: 2px solid $dark-blue;
      border-right: 6px solid $dark-blue;
      border-bottom: 6px solid $dark-blue;
      border-radius: 10px;
    }

    .mwq-space {
      gap: 20px;
    }

    .mwq-card-one {
      padding: 35px 18px;
    }

    .chart-sec {
      padding: 12px 0px;
    }
  }

  .plan-action-sec {
    // .gray-sec {
    //     background-color: #4A4A4A;
    // }
    .diet-plan-phase {
      // border-collapse: collapse;
      width: 100%;

      td,
      th {
        border: 1.5px solid $black;
        text-align: left;
        padding: 8px;
      }

      tr:nth-child(even) {
        background-color: $light-axe;
      }
    }
  }
}
