.multi-select-dropdown {
    position: relative;
    /* font-family: Arial, sans-serif;
    width: 240px; */

}
.quantity_variation input[type='number']:disabled {
    background: #fff;
}
.Dropdown-section-main {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;
    row-gap: 20px; */
}


.mealtime-dropdown {
    display: flex;
    align-items: center;
    gap: 35px;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: center;

}

.mealtime-dropdown p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.multi-select-dropdown .dropdown {
    cursor: pointer;
    padding: 3px 15px 5px;
    border: 1px solid #d8dbe0;
    border-radius: 25px;
    display: flex;
    align-items: center;
    color: #2c384af2;
    justify-content: center;
}

.multi-select-dropdown .options-dropdown {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #d8dbe0;
    border-radius: 6px;
    width: 100%;
}

.option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    cursor: pointer;
    border-bottom: 1px solid #d8dbe0;
}

.options-dropdown input[type='checkbox'] {
    margin-right: 8px;
}


.associate-btn-section .associateModal-btn {
    width: 190px;
    border-radius: 10px;
    color: var(--white);
    background-color: var(--primaryBlue);
    border: none;
    padding: 3px 15px 5px;
    font-weight: 600;

}

.input-dropdown-section {
    /* display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center; */
}

.input-dropdown-section p {
    /* font-size: 18px;
    font-weight: 600;
    margin: 0; */
}

.input-dropdown-section input::placeholder {
    color: #2c384af2;
}

.input-dropdown-section input {
    border-radius: 25px;
    padding: 3px 15px 5px;
    width: 250px;
    text-align: center;
    background-color: transparent;
    border: 1px solid #d8dbe0;
}
input, select, p{
    font-size: 14px;
}
.container-main {
    text-align: center;
    margin: 5px 0;
}

.Value-Unit-section {
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #d8dbe0;
}

.input-box-main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
}

.container-main p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.input-box-main input::placeholder {
    color: #2c384af2;
}

.input-box-main input {
    background-color: transparent;
    border-radius: 25px;
    padding: 3px 15px 5px;
    width: 140px;
    text-align: center;
    border: 1px solid #d8dbe0;
    font-size: 14px;
}

.input-box-main select {
    background-color: transparent;
    border-radius: 25px;
    padding: 3px 15px 5px;
    width: 100%;
    text-align: left;
    border: 1px solid #d8dbe0;
    font-size: 14px;
}

.Measure-dropdown option {
    background-color: transparent;
    text-align: left;
    border-radius: 20px;
}

.mealtime-dropdown_ p, .input-dropdown-section p{
    text-align: center !important;
}

.periodpal-app .save-btn.save-btn-tags{
    padding: 10px 10px 15px 10px !important;
}

.Measure-dropdown {
    appearance: none;
    background-image: url('../assets/images/svg/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: 90% center;
}

.button-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    row-gap: 20px;
}

.button-section .save-btn {
    background-color: var(--primaryBlue);
    color: var(--white);
    padding: 12px;
    border-radius: 10px;
    font-weight: 600;
    border: none;
    width: 150px;
}

.button-section .cancel-btn {
    background-color: var(--btnBlack);
    color: var(--white);
    padding: 12px;
    border-radius: 10px;
    font-weight: 600;
    border: none;
    width: 150px;

}


.associate-tag-modal .associatemodal-body {
    padding: 25px;
}

.associate-tag-modal .checkbox-section-main {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin: 5px 0;
}

.associate-tag-modal .tags-container {
    margin: 20px 0;
}

.associate-tag-modal .input-section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
}

.associate-tag-modal .tags-container .Heading-text {
    font-size: 16px;
    font-weight: 600;
}

.associate-tag-modal .input-section p {
    margin: 0;
}

.associate-tag-modal .container-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.associate-tag-modal .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.associate-tag-modal .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: #d0d0d0;
}

/* On mouse-over, add a grey background color */
.associate-tag-modal .container-checkbox:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.associate-tag-modal .container-checkbox input:checked~.checkmark {
    background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.associate-tag-modal .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.associate-tag-modal .container-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.associate-tag-modal .container-checkbox .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.associate-tag-modal .save-button-section {
    display: flex;
    justify-content: center;
}

.associate-tag-modal .save-btn {
    background-color: var(--primaryBlue);
    color: var(--white);
    padding: 12px;
    border-radius: 10px;
    font-weight: 600;
    border: none;
    width: 150px;
}

@media only screen and (max-width:600px) {
    .associate-btn-section {
        margin: auto;

    }
}