.navigation-head {
    display: flex;
    gap: 20px;
    align-items: center;
}

.navigation-head .header-text h3 {
    margin: 0;
}

.navigation-head img {
    cursor: pointer;
}

.Meal-Master .dropdown-sections {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
}

.Meal-Master .dropdown-sections .filters-section {
    display: flex;
    gap: 20px;
    align-items: center;
}


.Meal-Master .input-search-section {
    position: relative;
    display: inline-block;
    font-size: 16px;
    width: 300px;
}


.Meal-Master .input-search-section input {
    width: 100%;
    padding: 3px 15px 5px;
    border: 1px solid #d8dbe0;
    border-radius: 25px
}


.Meal-Master .input-search-section .icon {
    position: absolute;
    top: 15px;
    right: 20px;
}

.Meal-Master .dropdown-filter .filter:focus {
    background-color: var(--white);
    border-color: #d8dbe0;
}

.Meal-Master .dropdown-filter .filter {
    background-color: var(--white);
    border: 1px solid #d8dbe0;
    border-radius: 35px;
    padding: 3px 15px 5px;
    text-align: center;
    color: var(--black);
    width: 150px;
}

.Meal-Master .dropdown-filter .filter-option {
    text-align: center;
    border-radius: 6px;
    padding: 5px;
}

.Meal-Master .addMeal-button-section .addmeal-button {
    width: 150px;
    border-radius: 10px;
    color: var(--white);
    background-color: var(--primaryBlue);
    border: none;
    padding: 10px;
}

.periodpal-app .Meal-Master .btn-primary{
    padding: 2px 20px 3px 20px;
}

.Meal-Master .table-section {
    margin-top: 10px;
}

.Meal-Master .rdt_Table {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.Meal-Master .rdt_TableHeadRow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--primaryBlue);
    color: var(--white);
    font-size: 16px;
}

.Meal-Master .textshow img {
    width: 20px;
    margin: 0 5px;
    cursor: pointer;
}

.Meal-Master .textshow {
    display: inline;
}

.Meal-Master .textshow:hover .aftertext {
    visibility: visible;
}

.Meal-Master .textshow .aftertext {
    visibility: hidden;
    width: 120px;
    background: #615f5f;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 80%;
    left: 50%;
    margin-left: -60px;
}

/* pagination css  */

.Table-pagination {
    margin: 30px 10px;
}

.Table-pagination .Pagination-section-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Table-pagination .Pagination-section-main .pages-arrows {
    display: flex;
    align-items: center;
    gap: 35px;
}

.Table-pagination .Pagination-section-main .pages-arrows img {
    border: 1px solid #d8dbe0;
    padding: 5px;
    cursor: pointer;
}

.Table-pagination .Pagination-section-main .pages-arrows p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.Table-pagination .Pagination-section-main .records-section p {
    font-size: 14px;
    font-weight: 600;
}